import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container } from "react-bootstrap"
import ContactData from "../components/contactdata"
import TopicList from "../components/topicList"
import CheckText from "../components/textwithcheck"
import Aos from "aos"
import ZapisConsult from "../components/zapisConsultation"
import PortableText from "react-portable-text"

const FaQ = ({ data }) => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const { title, header, header2, listofFAQQuestions } = data.sanityFaq
  const serializer = {
    strong: props => <strong className="text-white" {...props} />,
  }
  return (
    <Layout>
      <Seo
        title={title}
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          {/* photo and text block */}
          <Col
            xl={8}
            lg={8}
            md={12}
            sm={12}
            xs={12}
            className="py-5 arrowbackground"
          >
            <Container fluid="lg">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="text-white">
                    <h1 className="display-6 uk-heading-divider">
                      {header}
                      <br />
                      <span style={{ fontWeight: "200" }}>{header2}</span>
                    </h1>
                    <div>
                      {listofFAQQuestions.map(question => (
                        <section key={question._key}>
                          <h2>{question.question}</h2>
                          <PortableText
                            className="uk-text-meta"
                            content={question._rawBody}
                            serializers={serializer}
                          />
                        </section>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
          </Col>
        </Row>
      </Container>
      <div style={{ width: "100%" }} className="bg-secondary">
        <Container fluid="xl">
          <Row>
            <Col
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={12}
              className="py-5 text-right"
            >
              <ZapisConsult />
            </Col>
            <Col
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="bg-primary p-5"
            >
              <ContactData />
            </Col>
          </Row>
        </Container>
      </div>
      <CheckText text="опыт&nbsp;работы 10&nbsp;лет" />
    </Layout>
  )
}

export const query = graphql`
  {
    sanityFaq {
      title
      header
      header2
      listofFAQQuestions {
        question
        _rawBody
      }
    }
  }
`

export default FaQ
